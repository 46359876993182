const bannerhome1 = [
    {
        id: 1,
        text1:'',
        text2:'ESPECIALISTAS EN PROYECTOS Y CONSTRUCCIÓN DE EDIFICACIONES',
        text3:'',
        textboton:'NOSOTROS',
        srcimg: 'assets/img/slider/slider-1-new.png',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700',
        datax: "['left','left','left','center']",
        datahset: "['34','34','34','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: "tp-caption",
        linkto: "/about-us"
    },
    {
        id: 2,
        text1:'',
        text2:'11 AÑOS DE EXPERIENCIA',
        text3:'',
        textboton:'SERVICIOS',
        srcimg: 'assets/img/slider/slider-2-new.png',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
        datax: "['center','center','center','center']",
        datahset: "['0','0','0','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: "tp-caption text-center",
        linkto: "/services"
    },
    {
        id: 3,
        text1:'',
        text2:'VERSATILIDAD COMPROBADA',
        text3:'',
        textboton:'PROYECTOS',
        srcimg: 'assets/img/slider/slider-3-new.png',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
        datax: "['right','right','right','center']",
        datahset: "['-14','-14','-14','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: "tp-caption text-right",
        linkto: "/project"
    }
]

export default bannerhome1;