import React, { Component } from 'react';

class ContentProject extends Component {
    render() {
        return (
            <div className="detail-gallery">
                <div className="themesflat-spacer clearfix" data-desktop={21} data-mobile={21} data-smobile={21} />
                <div className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
                    data-gap={0} data-column={1} data-column2={1} data-column3={1} data-auto="false">
                    <div className="owl-carousel owl-theme">
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="assets/img/project/residencial/tripoli/tripoli1.png" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="assets/img/project/residencial/tripoli/tripoli2.png" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="assets/img/project/residencial/tripoli/tripoli3.png" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                        <div className="gallery-item">
                            <div className="inner">
                                <div className="thumb">
                                    <img src="assets/img/project/residencial/tripoli/tripoli4.png" alt="imagealt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.themesflat-cousel-box */}
                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">DESCRIPCIÓN DEL PROYECTO</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <p className="margin-top-28">OBRA : “EJECUCION DE ACONDICIONAMIENTO DE ACABADOS DE EDIFICIO MULTIFAMILIAR TRIPOLI”.</p>
                </div>
            </div>
        );
    }
}

export default ContentProject;