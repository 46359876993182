import Home01 from './Home01'
import Home02 from './Home02'
import AboutUs from './AboutUs'
import AboutDetails from './AboutDetails'
import AboutTeam from './AboutTeam'
import AboutCarres from './AboutCarres'
import Services from './Services'
import ServiceDetail from './ServiceDetail'
import Projects from './Projects'
import ProjectFullWidth from './ProjectFullWidth'
import ProjectDetail from './ProjectDetail'
import TiendaChiclayo from './Project/desarrollointegral/tienda_chiclayo'
import Testimonial from './Testimonial'
import PricePage from './PricePage'
import Blog from './Blog'
import BlogSingle from './BlogSingle'
import Contact01 from './Contact01'
import Contact02 from './Contact02'
import TiendaThnmegaplaza from './Project/desarrollointegral/tienda_thn_megaplaza'
import HotelPueblolibre from './Project/desarrollointegral/hotel_pueblolibre'
import CasaDescansopaul from './Project/desarrollointegral/casa_descansopaul'
import TiendaThnsanmiguel from './Project/desarrollointegral/tienda_thn_sanmiguel'
import TiendaMegaplaza from './Project/desarrollointegral/tienda_megaplaza'
import TiendaCamacho from './Project/acondicionamiento/tienda_camacho'
import TiendaThnPlazanorte from './Project/acondicionamiento/tienda_thn_plnorte'
import OficinaAcosta from './Project/acondicionamiento/oficina_acosta'
import OficinasDaca from './Project/acondicionamiento/oficina_daca'
import OficinasLucarma from './Project/acondicionamiento/oficina_lucarma'
import AlmacenesIntersport from './Project/comerciales/almacenes_intersport'
import HotelLamar from './Project/comerciales/hotel_lamar'
import VestuarioCricket from './Project/comerciales/vestuario_cricket'
import SaunaCricket from './Project/comerciales/sauna_cricket'
import GimnasioCricket from './Project/comerciales/gimnasio_cricket'
import CasaDracosta from './Project/residencial/casa_dracosta'
import JorgeChavez from './Project/residencial/jorge_chavez'
import CatalinoMiranda from './Project/residencial/catalino_miranda'
import RicardoPalma from './Project/residencial/ricardo_palma'
import Tripoli from './Project/residencial/tripoli'
import OficinasSunarp from './Project/institucionales/oficina_sunarp'
import HospitalAbancay from './Project/institucionales/hospital_abancay'
import ColegioGrau from './Project/institucionales/colegio_grau'
import CorteDeLima from './Project/institucionales/institucional_corte'
import ProjectsDesarrolloIntegral from './ProjectsDesarrolloIntegral'
import ProjectsAcondicionamiento from './ProjectsAcondicionamiento'
import ProjectsAmpliaciones from './ProjectsAmpliaciones'
import TiendaSanJuan from './Project/acondicionamiento/tienda_sanjuan'

const routes = [
    { path: '/', component: Home01},
    { path: '/home-02', component: Home02},
    { path: '/about-us', component: AboutUs},
    { path: '/about-detail', component: AboutDetails},
    { path: '/about-team', component: AboutTeam},
    { path: '/about-carees', component: AboutCarres},
    { path: '/services', component: Services},
    { path: '/services-detail', component: ServiceDetail},
    { path: '/project', component: Projects},
    { path: '/project-desarrollo-integral', component: ProjectsDesarrolloIntegral},
    { path: '/project-acondicionamiento', component: ProjectsAcondicionamiento},
    { path: '/project-ampliaciones', component: ProjectsAmpliaciones},
    { path: '/project-full-width', component: ProjectFullWidth},
    { path: '/project-detail', component: ProjectDetail},
    { path: '/page-testimonial', component: Testimonial},
    { path: '/page-pricing', component: PricePage},
    { path: '/blog', component: Blog},
    { path: '/blog-single', component: BlogSingle},
    { path: '/contact-01', component: Contact01},
    { path: '/contact-02', component: Contact02},
    { path: '/tienda-chiclayo', component: TiendaChiclayo},
    { path: '/tienda-megaplaza', component: TiendaMegaplaza},
    { path: '/tienda-thn-sanmiguel', component: TiendaThnsanmiguel},
    { path: '/tienda-thn-plazanorte', component: TiendaThnmegaplaza},
    { path: '/hotel-pueblolibre', component: HotelPueblolibre},
    { path: '/casa-descansopaul', component: CasaDescansopaul},
    { path: '/tienda-camacho', component: TiendaCamacho},
    { path: '/tienda-thn-plazanorte-acondicionamiento', component: TiendaThnPlazanorte},
    { path: '/oficinas-acosta', component: OficinaAcosta},
    { path: '/oficinas-daca', component: OficinasDaca},
    { path: '/oficinas-lucarma', component: OficinasLucarma},
    { path: '/tienda-sanjuan', component: TiendaSanJuan},
    { path: '/almacenes-intersport', component: AlmacenesIntersport},
    { path: '/gimnasio-cricet', component: GimnasioCricket},
    { path: '/gimnasio-evolution', component: SaunaCricket},
    { path: '/oficina-cricket', component: VestuarioCricket},
    { path: '/hotel-lamar', component: HotelLamar},
    { path: '/tripoli', component: Tripoli},
    { path: '/ricardo-palma', component: RicardoPalma},
    { path: '/catalino-miranda', component: CatalinoMiranda},
    { path: '/jorge-chavez', component: JorgeChavez},
    { path: '/casa-dracosta', component: CasaDracosta},
    { path: '/oficinas-sunarp', component: OficinasSunarp},
    { path: '/hospital-abancay', component: HospitalAbancay},
    { path: '/corte-delima', component: CorteDeLima},
    { path: '/colegio-grau', component: ColegioGrau},

]

export default routes;