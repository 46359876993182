import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import BannerTitle from '../layouts/about/banner/BannerTitle'
import {Header, Footer, BottomBar} from '../layouts/general/index'
import {NewLayout} from '../layouts/services/index';
import {NewLayout2} from '../layouts/services/index';
import {NewLayout3} from '../layouts/services/index';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo_aljosi_x2.png",
                    names: "Servicios",
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Servicios',
                    heading: 'Todos nuestros servicios'
                }
            ]
        }
    }
    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            {/* <TopBar /> */}
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data =>(
                                <BannerTitle key={data.id} data={data}/>
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            {/* <ServiceSlide /> */}
                                            <NewLayout />
                                            <div className="themesflat-spacer clearfix" data-desktop={73} data-mobile={60} data-smobile={60} />
                                            <NewLayout2 />
                                            <div className="themesflat-spacer clearfix" data-desktop={73} data-mobile={60} data-smobile={60} />
                                            <NewLayout3 />
                                            <div className="themesflat-spacer clearfix" data-desktop={73} data-mobile={60} data-smobile={60} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-quote bg-row-1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="themesflat-spacer clearfix" data-desktop={40}
                                            data-mobile={60} data-smobile={60} />
                                        <div className="themesflat-quote style-1 clearfix">
                                            <div className="quote-item">
                                                <div className="inner">
                                                    <div className="heading-wrap">
                                                        <h3 className="heading">
                                                        </h3>
                                                    </div>
                                                    <div className="button-wrap has-icon icon-left">
                                                        <Link to="/contact-02" onClick={() => {window.location.href="/contact-02"}}
                                                            className="themesflat-button bg-white small">
                                                        <span>CONTÁCTANOS<span className="icon"><i
                                                                    className="autora-icon-phone-contact" /></span></span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="themesflat-spacer clearfix" data-desktop={31}
                                            data-mobile={60} data-smobile={60} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;