import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slidecard: [
                {
                    id:1,
                    srcimg: 'assets/img/imagebox/service-home-1.png',
                    heading: 'PROYECTOS INTEGRALES',
                    link: '/services',
                },
                {
                    id:2,
                    srcimg: 'assets/img/imagebox/service-home-2.png',
                    heading: 'EJECUCIÓN DE OBRAS DE ACONDICIONAMIENTO',
                    link: '/services',
                },
                {
                    id:3,
                    srcimg: 'assets/img/imagebox/service-home-3.png',
                    heading: 'EJECUCIÓN DE OBRAS DE REMODELACIÓN Y AMPLIACIÓN',
                    link: '/services',
                },
                // {
                //     id:4,
                //     srcimg: 'assets/img/imagebox/image-box-1-370x250.jpg',
                //     heading: 'DESIGN & BUILD'
                // },
                // {
                //     id:5,
                //     srcimg: 'assets/img/imagebox/image-box-2-370x250.jpg',
                //     heading: 'BUILDING ENVELOPES'
                // },
                // {
                //     id:6,
                //     srcimg: 'assets/img/imagebox/image-box-3-370x250.jpg',
                //     heading: 'CONSTRUCTION MANAGEMENT'
                // }
            ]
        }
    }
    
    render() {
        return (
            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3} data-column2={2}
                data-column3={1} data-auto="false">
                <div className="owl-carousel owl-theme">
                   
                        {
                            this.state.slidecard.map(data => (
                                <div className="themesflat-image-box style-1 has-icon icon-right w65 clearfix"key = {data.id}>
                                    <div className="image-box-item" >
                                        <div className="inner">
                                            <div className="thumb data-effect-item">
                                                <img src={data.srcimg} alt="altimage" />
                                                <div className="overlay-effect bg-color-accent" />
                                            </div>
                                            <div className="text-wrap">
                                                <h5 className="heading font-size-12 heading-especial"><Link to={data.link} onClick={() => {window.location.href=data.link}}>{data.heading}</Link></h5>
                                                <span className="icon-wrap">
                                                <Link to={data.link} onClick={() => {window.location.href=data.link}}><i className="fa fa-angle-right" /></Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                </div>
            </div>
        );
    }
}

export default Service;