import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { Counter } from '../layouts/about'
import BannerTitle from '../layouts/about/banner/BannerTitle'
import {TitleHeading , IconBox, AboutUsInt} from '../layouts/home01/index'
import {Header, Footer, BottomBar} from '../layouts/general/index'

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo_aljosi_x2.png",
                    names: "Nosotros",
                }
            ],
            headingchoose: [
                {
                id: 1,
                classnames: 'heading',
                title: 'SOMOS EL SOCIO QUE NECESITAS',
                text: 'Más de 10 años en el mercado de construcción y edificaciones.',
                classtext: 'sub-heading',
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Nosotros',
                    heading: 'Nosotros'
                }
            ]
        }
    }
    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            {/* <TopBar /> */}
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data =>(
                                <BannerTitle key={data.id} data={data}/>
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                            {
                                                            this.state.headingchoose.map(data => (
                                                            <TitleHeading data={data} key={data.id} />
                                                            ))
                                                            }
                                                            <div className="themesflat-spacer clearfix" data-desktop={42}
                                                                data-mobile={35} data-smobile={35} />
                                                        </div>
                                                    </div>

                                                    <IconBox />

                                                    <div className="themesflat-headings style-1 clearfix">
                                                        <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                        <p className="sub-heading">ALJOSI CONTRATISTAS GENERALES SAC se constituye como una empresa de emprendimiento familiar en abril de 2008 con el objetivo de dar soluciones de obra a empresas constructoras dedicadas a la ejecución de proyectos de edificación de todo tipo: Residencial, institucional, comercial, pública o privada.</p>
                                                        <p className="sub-heading">En sus inicios brinda servicios de obra menores, en la modalidad de subcontratos, hasta el año 2011, cuando comienza a ejecutar proyectos de mayor envergadura y desafío técnico. Son años de crecimiento, adquisición de experiencia y formación de nuevos equipos de trabajo. A partir del 2016, se centra en el sector privado retail comercial bajo la razón social ALJOSI SAC, especializándose en obras como tiendas, locales comerciales, oficinas, sedes institucionales, almacenamiento, entre otros.</p>
                                                        <p className="sub-heading">Hoy ALJOSI es una empresa consolidada que brinda soluciones integrales para obras de nueva edificación, acondicionamiento, remodelación y ampliación. Fundada por el Arq. Juan José San Martín y la Lic. María Consuelo Tavera, la empresa ha sabido establecerse en el mercado como una alternativa atractiva, con una interesante cartera de clientes y un grupo de obras finalizadas que dan fe de sus principales atributos:</p>
                                                        <div className="themesflat-spacer clearfix" data-desktop={42}
                                                                data-mobile={35} data-smobile={35} />
                                                        <p className="sub-heading">
                                                            <ul>
                                                                <li>- Mirada integral al proyecto.</li>
                                                                <li>- Conocimiento de diversas tipologías de obra.</li>
                                                                <li>- Equipos multidisciplinarios.</li>
                                                                <li>- Acompañamiento constante al cliente.</li>
                                                                <li>- Calidad en cada aspecto del proceso.</li>
                                                                <li>- Programación y cumplimento de plazos.</li>
                                                                <li>- Costos adecuados.</li>
                                                            </ul>
                                                        </p>  
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {/* <div className="themesflat-spacer clearfix" data-desktop={41}
                                                                data-mobile={35} data-smobile={35} />
                                                            <div className="elm-button text-center">
                                                                <Link to="#" className="themesflat-button bg-accent">ABOUT US</Link>
                                                            </div> */}
                                                            <div className="themesflat-spacer clearfix" data-desktop={73}
                                                                data-mobile={60} data-smobile={60} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <AboutUsInt />
                                            {/* <div className="themesflat-spacer clearfix" data-desktop={73}
                                                                data-mobile={60} data-smobile={60} />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="themesflat-spacer clearfix" data-desktop={41} data-mobile={35} data-smobile={35} />
                                                    <p className="sub-heading margin-top-30">En sus inicios brinda servicios de obra menores, en la modalidad de subcontratos, hasta el año  2011, cuando comienza a ejecutar proyectos de mayor envergadura y desafío técnico. Son años de crecimiento, adquisición de experiencia y formación de nuevos equipos de trabajo. A partir del 2016, se centra en el sector privado retail comercial bajo la razón social ALJOSI SAC, especializándose en obras como tiendas, locales comerciales, oficinas, sedes institucionales, almacenamiento, entre otros.</p>
                                                    <div className="elm-button text-center">
                                                        <Link to="/about-us" onClick={() => {window.location.href="/about-us"}} className="themesflat-button bg-accent">NOSOTROS</Link>
                                                    </div>
                                                    <div className="themesflat-spacer clearfix" data-desktop={73} data-mobile={60} data-smobile={60} />
                                                </div>
                                            </div> */}
                                            <Counter />
                                            {/* <div className="row-tabs">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={61}
                                                                data-mobile={60} data-smobile={60} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <Overview />
                                                        <Faq />
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <Partner /> */}
                                            <div className="row-quote bg-row-1">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={40}
                                                                data-mobile={60} data-smobile={60} />
                                                            <div className="themesflat-quote style-1 clearfix">
                                                                <div className="quote-item">
                                                                    <div className="inner">
                                                                        <div className="heading-wrap">
                                                                            <h3 className="heading">BIENVENIDO Y BIENVENIDA A NUESTRA WEB. <br></br>ESTAMOS A SU SERVICIO.
                                                                            </h3>
                                                                        </div>
                                                                        <div className="button-wrap has-icon icon-left">
                                                                            <Link to="/contact-02" onClick={() => {window.location.href="/contact-02"}}
                                                                                className="themesflat-button bg-white small">
                                                                            <span>CONTÁCTANOS<span className="icon"><i
                                                                                        className="autora-icon-phone-contact" /></span></span>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="themesflat-spacer clearfix" data-desktop={31}
                                                                data-mobile={60} data-smobile={60} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <LeaderTeam /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;