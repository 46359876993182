const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
        // namesub: [
        //     {
        //     //     id: 1,
        //     //     sub: 'Home 01',
        //     //     links: '/'
        //     // },
        //     // {
        //     //     id: 2,
        //     //     sub: 'Home 02',
        //     //     links: '/home-02'
        //     },
        // ]
    },
    {
        id: 2,
        name: 'Nosotros',
        links: '/about-us',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'About Us',
        //         links: '/about-us'
        //     },
        //     {
        //         id: 2,
        //         sub: 'About Detail',
        //         links: '/about-detail'
        //     },
        //     {
        //         id: 3,
        //         sub: 'About Team',
        //         links: '/about-team'
        //     },
        //     {
        //         id: 4,
        //         sub: 'About Carees',
        //         links: '/about-carees'
        //     }
        // ],
    },{
        id: 3,
        name: 'Servicios',
        links: '/services',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Services',
        //         links: '/services'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Services Detail',
        //         links: '/services-detail'
        //     }
        // ],
    },
    {
        id: 4,
        name: 'Proyectos',
        links: '/project',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Projects',
        //         links: '/project'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Projects Full Width',
        //         links: '/project-full-width'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Projects Detail',
        //         links: '/project-detail'
        //     }
        // ],
    },
    // {
    //     id: 5,
    //     name: 'Page',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Page Testimonial',
    //             links: '/page-testimonial'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Page Pricing',
    //             links: '/page-pricing'
    //         }
    //     ],
    // },
    // {
    //     id: 6,
    //     name: 'Blog',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Blog',
    //             links: '/blog'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Blog Single',
    //             links: '/blog-single'
    //         }
    //     ],
    // },
    {
        id: 5,
        name: 'Contáctanos',
        links: '/contact-02',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Contact 1',
        //         links: '/contact-01'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Contact 2',
        //         links: '/contact-02'
        //     }
        // ],
    }
]

export default menus;