import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class TabProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    id: 1,
                    classnames: 'project-item integral',
                    srcimg: 'assets/img/project/desarrollointegral/tienda_chiclayo/tiendachiclayo_portada.png',
                    heading: 'TIENDA TRIATHLON C.C. MALL CHICLAYO',
                    name01: 'Proyectos Integrales',
                    name02: 'Integral',
                    linkto: 'tienda-chiclayo'
                },
                {
                    id: 2,
                    classnames: 'project-item integral',
                    srcimg: 'assets/img/project/desarrollointegral/tienda_megaplaza/tienda_megaplaza_portada.png',
                    heading: 'TIENDA TRIATHLON C.C. MEGAPLAZA',
                    name01: 'Proyectos Integrales',
                    name02: '',
                    linkto: 'tienda-megaplaza'
                },
                {
                    id: 3,
                    classnames: 'project-item integral',
                    srcimg: 'assets/img/project/desarrollointegral/tienda_thn_sanmiguel/tienda_thn_sanmiguel_portada.png',
                    heading: 'TIENDA THN C.C. PLAZA SAN MIGUEL',
                    name01: 'Proyectos Integrales',
                    name02: '',
                    linkto: 'tienda-thn-sanmiguel'
                },
                {
                    id: 4,
                    classnames: 'project-item integral',
                    srcimg: 'assets/img/project/desarrollointegral/tienda_thn_megaplaza/tienda_plaza_norte_portada.png',
                    heading: 'TIENDA THN C.C. LIMA PLAZA NORTE',
                    name01: 'Proyectos Integrales',
                    name02: '',
                    linkto: 'tienda-thn-plazanorte'
                },
                {
                    id: 5,
                    classnames: 'project-item integral',
                    srcimg: 'assets/img/project/desarrollointegral/hotel_pueblolibre/hotel_pueblolibre_portada.png',
                    heading: 'HOTEL 3 ESTRELLAS PUEBLO LIBRE',
                    name01: 'Proyectos Integrales',
                    name02: '',
                    linkto: 'hotel-pueblolibre'
                },
                {
                    id: 6,
                    classnames: 'project-item integral',
                    srcimg: 'assets/img/project/desarrollointegral/casa_descansopaul/casa_descansopaul3.png',
                    heading: 'VIVIENDA UNIFAMILIAR DE DESCANSO',
                    name01: 'Proyectos Integrales',
                    name02: '',
                    linkto: 'casa-descansopaul'
                },
                {
                    id: 7,
                    classnames: 'project-item acondicionamiento',
                    srcimg: 'assets/img/project/acondicionamiento/tienda_camacho/tienda_camacho_portada.png',
                    heading: 'TIENDA EN C.C. PLAZA CAMACHO',
                    name01: 'Obras de acondicionamiento',
                    name02: '',
                    linkto: 'tienda-camacho'
                },
                {
                    id: 8,
                    classnames: 'project-item acondicionamiento',
                    srcimg: 'assets/img/project/acondicionamiento/tienda_thn_plnorte/tienda_thn_plazanorte_portada.png',
                    heading: 'TIENDA THN C.C. LIMA PLAZA NORTE',
                    name01: 'Obras de acondicionamiento',
                    name02: '',
                    linkto: 'tienda-thn-plazanorte-acondicionamiento'
                },
                {
                    id: 9,
                    classnames: 'project-item acondicionamiento',
                    srcimg: 'assets/img/project/acondicionamiento/oficina_acosta/oficina_acosta_portada.png',
                    heading: 'OFICINA ESTUDIO ACOSTA CONSULTORES',
                    name01: 'Obras de acondicionamiento',
                    name02: '',
                    linkto: 'oficinas-acosta'
                },
                {
                    id: 10,
                    classnames: 'project-item acondicionamiento',
                    srcimg: 'assets/img/project/acondicionamiento/oficina_daca/oficinas_daca_portada.png',
                    heading: 'OFICINA DACA INGENIERIA',
                    name01: 'Obras de acondicionamiento',
                    name02: '',
                    linkto: 'oficinas-daca'
                },
                {
                    id: 11,
                    classnames: 'project-item acondicionamiento',
                    srcimg: 'assets/img/project/acondicionamiento/oficina_lucarma/oficinas_lucarma_portada.png',
                    heading: 'OFICINAS GRUPO LUCARMA',
                    name01: 'Obras de acondicionamiento',
                    name02: '',
                    linkto: 'oficinas-lucarma'
                },
                {
                    id: 12,
                    classnames: 'project-item acondicionamiento',
                    srcimg: 'assets/img/project/acondicionamiento/oficina_sanjuan/oficina_sanjuan_portada.png',
                    heading: 'TIENDA THN Y CATALOGO SAN JUAN DE LURIGANCHO',
                    name01: 'Obras de acondicionamiento',
                    name02: '',
                    linkto: 'tienda-sanjuan'
                },
                {
                    id: 13,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/comerciales/almacenes_intersport/almacenes_intersport_portada.png',
                    heading: 'ALMACENES DE INTERSPORT',
                    name01: 'Remodelaciones | Comerciales',
                    name02: 'Comerciales',
                    linkto: 'almacenes-intersport'
                },
                {
                    id: 14,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/comerciales/hotel_lamar/hotel_lamar_portada.png',
                    heading: 'HOTEL LA MAR 3 ESTRELLAS',
                    name01: 'Remodelaciones | Comerciales',
                    name02: 'Comerciales',
                    linkto: 'hotel-lamar'
                },
                {
                    id: 15,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/comerciales/gimnasio_cricket/gimnasio_cricket_portada.png',
                    heading: 'GIMNASIO DE CLUB LIMA CRICKET',
                    name01: 'Remodelaciones | Comerciales',
                    name02: 'Comerciales',
                    linkto: 'gimnasio-cricet'
                },
                {
                    id: 16,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/comerciales/vestuario_cricket/vestuario_cricket_portada.png',
                    heading: 'OFICINAS ADMINISTRATIVAS LIMA CRICKET',
                    name01: 'Remodelaciones | Comerciales',
                    name02: 'Comerciales',
                    linkto: 'oficina-cricket'
                },
                {
                    id: 17,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/residencial/tripoli/tripoli_portada.png',
                    heading: 'EDIFICIO MULTIFAMILIAR TRIPOLI',
                    name01: 'Remodelaciones | Residenciales',
                    name02: 'Residenciales',
                    linkto: 'tripoli'
                },
                {
                    id: 18,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/residencial/ricardo_palma/ricardo_palma_portada.png',
                    heading: 'EDIFICIO MULTIFAMILIAR RICARDO PALMA',
                    name01: 'Remodelaciones | Residenciales',
                    name02: 'Residenciales',
                    linkto: 'ricardo-palma'
                },
                {
                    id: 19,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/residencial/catalino_miranda/catalino_miranda_portada.png',
                    heading: 'EDIFICIO CATALINO MIRANDA 453',
                    name01: 'Remodelaciones | Residenciales',
                    name02: 'Residenciales',
                    linkto: 'catalino-miranda'
                },
                {
                    id: 20,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/residencial/jorge_chavez/jorge_chavez_portada.png',
                    heading: 'EDIFICIO MULTIFAMILIAR JORGE CHAVEZ 526',
                    name01: 'Remodelaciones | Residenciales',
                    name02: 'Residenciales',
                    linkto: 'jorge-chavez'
                },
                {
                    id: 21,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/residencial/casa_dracosta/casa_dracosta4.png',
                    heading: 'VIVIENDA UNIFAMILIAR',
                    name01: 'Remodelaciones | Residenciales',
                    name02: 'Residenciales',
                    linkto: 'casa-dracosta'
                },
                {
                    id: 22,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/institucional/oficinas_sunarp/oficinas_sunarp_portada.png',
                    heading: 'OFICINAS SUNARP',
                    name01: 'Remodelaciones | Institucionales',
                    name02: 'Institucionales',
                    linkto: 'oficinas-sunarp'
                },
                {
                    id: 23,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/institucional/hospital_abancay/hospital_abancay_portada.png',
                    heading: 'NUEVO HOSPITAL II ABANCAY- ESSALUD',
                    name01: 'Remodelaciones | Institucionales',
                    name02: 'Institucionales',
                    linkto: 'hospital-abancay'
                },
                {
                    id: 24,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/institucional/corte_de_lima/corte_delima_portada.png',
                    heading: 'NUEVA CORTE SUPERIOR DE LIMA',
                    name01: 'Remodelaciones | Institucionales',
                    name02: 'Institucionales',
                    linkto: 'corte-delima'
                },
                {
                    id: 25,
                    classnames: 'project-item ampliaciones',
                    srcimg: 'assets/img/project/institucional/colegio_grau/colegio_grau_portada.png',
                    heading: 'COLEGIO EMBLEMATICO IE GRAU',
                    name01: 'Remodelaciones | Institucionales',
                    name02: '',
                    linkto: 'colegio-grau'
                }
            ]
        }
    }

    render() {
        return (
            <div className="themesflat-project style-2 isotope-project has-margin mg15 data-effect clearfix">
                {
                this.state.projects.map(data =>(
                <div className={data.classnames} key={data.id}>
                    <div className="inner">
                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-19 offset-h-24">
                            <img src={data.srcimg} alt="altimage" />
                            <div className="elm-link">
                                {/* <Link to="#" className="icon-1 icon-search" /> */}
                                    <Link to={data.linkto} onClick={() => {window.location.href=data.linkto}} className="icon-1" />
                            </div>
                            <div className="overlay-effect bg-color-3" />
                        </div>
                        <div className="text-wrap">
                            <h5 className="heading">
                                <Link to={data.linkto} onClick={() => {window.location.href=data.linkto}}>{data.heading}</Link>
                            </h5>
                            <div className="elm-meta">
                                <span>
                                    <Link to="#">{data.name01}</Link></span>
                                {/* <span>
                                    <Link to="#">{data.name02}</Link></span> */}
                            </div>
                        </div>
                    </div>
                </div>
                ))
                }
            </div>
        );
    }
}

export default (TabProject);